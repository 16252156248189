<template>
  <div id="guest-upgrade-step-1">
    <!-- TODO: Uncomment this when we re-enable in-app subscriptions and upgrades.
    <FormPlanButtonsSelect
      v-if="planOptions.length && !isAmazonDevice"
      :options="planOptions"
      bind-id="plan"
      aria-label="plan"
    />
    -->
    <p class="call">
      To sign up, our Customer Care team is available daily from 6:00 AM to 6:00 PM Pacific Time at
      <a href="tel:1-800-835-1934">1-800-835-1934</a>.
      For additional contact options, visit
      <a href="https://aira.io/contact-us/">Contact Us</a>.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import FormPlanButtonsSelect from '../Form/FormPlanButtonsSelect.vue';
import UserAgentMixin from '../../mixin/UserAgentMixin';

export default {
  name: 'GuestUpgradeStep1',
  components: {
    // FormPlanButtonsSelect,
  },
  mixins: [
    UserAgentMixin,
  ],
  computed: {
    ...mapGetters([
      'planOptions',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.call {
  margin-top: 10px;
  text-align: center !important;
  font-weight: normal;
}
</style>
